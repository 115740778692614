<template>
  <div class="diligans-home-page">
    <div class="banner-wrapper">
      <div class="banner">
        <header-menu style="position: relative;" />
        <div class="banner-data">
          <div class="banner-text">
            <h2>Дилижанс</h2>
            <div class="banner-text-label">ваш эксперт в области грузоперевозок</div>
          </div>
          <div class="banner-text-list">
            <div>
              <img src="../assets/icons/chek.svg" />
              Большая география присутствия
            </div>
            <div>
              <img src="../assets/icons/chek.svg" />
              Индивидуальный подход к каждому клиенту
            </div>
            <div>
              <img src="../assets/icons/chek.svg" />
              Широкий спектр дополнительных услуг
            </div>
          </div>
          <img src="../assets/images/diligans_1.svg" class="banner-img" />
        </div>
        <div class="banner-calculator">
          <div>
            <div class="banner-calculator-manage">
              <strong>Отследить груз</strong>
              <div style="margin-top: 20px">
                <input
                  v-model="number"
                  class="t-input"
                  placeholder="Номер заказа"
                >
                <router-link
                  :to="{name: 'manage', query: {id: number}}"
                  class="t-button"
                  style="display: inline-flex; height: 40px; width: 40px"
                >
                  <img src="../assets/icons/search_simple.svg" width="12" alt="">
                </router-link>
              </div>
            </div>
            <div class="banner-calculator-market">
              <div style="margin-bottom: 15px">
                <strong>Доставка <br>в маркетплейсы</strong>
              </div>
              <router-link :to="{ name: 'order-create' }">
                Подробнее
              </router-link>
            </div>
          </div>
          <t-calculator style="z-index: 1000" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="links-wrapper">
        <router-link v-for="{ title, route, icon } of links" :key="title" :to="route">
          <img :src="icon" />
          <span>{{ title }}</span>
        </router-link>
      </div>
      <div class="about">
        <h5>Автомобильные грузоперевозки по России</h5>
        <p>
          Компания «Дилижанс» - это одна из ведущих транспортных компаний, которая специализируется на перевозке сборных грузов.
          Мы объединяем новейшие информационные технологии и многолетний опыт квалифицированных специалистов,
          чтобы обеспечить высокий уровень качества на всех этапах работы.
        </p>
        <div class="about-grid">
          <img src="../assets/images/diligans_2.jpg" style="grid-area: a" />
          <span class="text-warning" style="grid-area: b">Обеспечиваем надёжную логистику для бизнеса наших клиентов</span>
          <div style="grid-area: c">
            <p style="margin-top: 0">
              Мы предлагаем не только отдельные услуги, но и комплексные решения,
              позволяющие нашим партнерам передать управление логистикой их бизнеса в наши руки.
            </p>
            <p>Наши преимущества:</p>
            <ul>
              <li><b>Моментальное оповещение:</b> Информирование о статусе груза с момента приёма.</li>
              <li><b>Адресная доставка:</b> Доставка груза от двери до двери.</li>
              <li><b>Простое получение:</b> Получайте грузы по коду из SMS.</li>
              <li><b>Удобная оплата:</b> Оплачивайте перевозки онлайн или по QR-коду.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TCalculator from '@/components/TCalculator'
import {getCurrentInstance, ref} from 'vue'
import HeaderMenu from '@/components/HeaderMenu'

export default {
  name: 'HomePage',
  components: {HeaderMenu, TCalculator},
  setup() {
    const {companyData} = getCurrentInstance().appContext.config.globalProperties

    const number = ref('')

    const links = [
      {
        title: 'Рассчитать стоимость',
        icon: require('../assets/icons/calc.svg'),
        route: { name: 'order-create' }
      },
      {
        title: 'Отследить груз',
        icon: require('../assets/icons/search.svg'),
        route: { name: 'manage' }
      },
      {
        title: 'География грузоперевозок',
        icon: require('../assets/icons/geo.svg'),
        route: { name: 'geo' }
      },
      {
        title: 'Документы компании',
        icon: require('../assets/icons/docs.svg'),
        route: { name: 'documents' }
      },
      {
        title: 'Тарифы на услуги',
        icon: require('../assets/icons/coins.svg'),
        route: { name: 'tariffs' }
      },
      {
        title: 'Контакты',
        icon: require('../assets/icons/phone.svg'),
        route: { name: 'contacts' }
      },
    ]

    return {
      companyData,
      number,
      links
    }
  }
}
</script>

<style scoped>
.diligans-home-page {
  font-family: 'Roboto', sans-serif;
}
.diligans-home-page :deep(.menu-wrapper-header-title) {
  color: var(--font-2);
}

.container {
  display: flex;
  flex-direction: column;
  width: 97%;
  max-width: 1240px;
  margin: 60px auto 40px;
  align-items: center;
  justify-content: center;
}
.banner-wrapper {
  background-color: #F8F8F8;
}
.banner {
  max-width: 1240px;
  width: 97%;
  margin: auto;
}

.banner-data {
  text-align: left;
  align-items: flex-start;
  display: grid;
  gap: 20px;
  grid-template:
    "a c"
    "b c";
}
.banner-text {
  grid-area: a;
  font-family: 'Open Sans', sans-serif;
}
.banner-img {
  grid-area: c;
}

.banner-calculator {
  display: flex;
  /*flex-wrap: wrap;*/
  text-align: left;
  margin-top: 60px;
  gap: 20px;
  padding-bottom: 40px;
}
.banner-calculator > div:first-child {
  min-width: min(380px, 100%);
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.banner-calculator > div:last-child {
  flex-grow: 1;
}
.banner-calculator-manage {
  border-radius: 12px;
  border: 1px solid var(--line);
  background: white;
  padding: 30px 40px;
  flex-grow: 1;
}
.banner-calculator-manage strong {
  font-size: 22px;
  font-weight: 500;
}
.banner-calculator-manage > div {
  display: flex;
  gap: 20px;
}
.banner-calculator-market {
  border-radius: 12px;
  background-image: linear-gradient(140deg, #FFE925 70%, #FBB313);
  padding: 30px;
  position: relative;
  flex-grow: 1;
}
.banner-calculator-market::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url('../assets/images/diligans_box.png');
  background-size: 280px;
  background-repeat: no-repeat;
  background-position: right -90px top 50%;
  pointer-events: none;
}
.banner-calculator-market strong {
  font-size: 22px;
  font-weight: 500;
}
.banner-calculator :deep(.calculator) {
  max-width: unset;
  text-align: center;
}

.banner-data h2 {
  color: var(--primary-color);
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 6px;
}
.banner-data .banner-text-label {
  color: var(--font-1);
  font-size: min(9.5vw, 42px);
  font-weight: 600;
  text-transform: uppercase;
}
.banner-data .banner-text-list {
  margin-top: 20px;
  color: var(--font-2);
  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  grid-area: b;
}
.banner-data .banner-text-list > div {
  display: flex;
  gap: 15px;
}
.banner-data .banner-text-list img {
  filter: var(--primary-filter)
}

.container .container-data img {
  object-fit: contain;
}

.container .container-data h3 {
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 0;
}

.container .container-data strong {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}

.links-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}
.links-wrapper > a {
  z-index: 200;
  padding: 44px 20px 25px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  color: var(--font-1);
  text-decoration: unset;
  border: 1px solid var(--line);
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  transition: 200ms;
}
.links-wrapper > a img {
  filter: var(--primary-filter);
}

.links-wrapper > a:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}
.links-wrapper > a:hover img {
  filter: brightness(0) invert(1);
}

.about {
  text-align: left;
}
.about h5 {
  color: var(--font-1);
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 30px;
}
.about-grid {
  display: grid;
  gap: 50px;
  grid-template:
    "a b"
    "a c";
  margin-top: 50px;
}

@media screen and (max-width: 1240px) {
  .banner {
    max-width: 1047px;
  }
  .banner-img {
    width: 480px;
  }
  .links-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .banner-calculator {
    flex-direction: column;
  }
  .banner-calculator > div:first-child {
    flex-direction: row;
  }
  .about-grid {
    grid-template:
    "b b"
    "a c";
  }
  .about-grid img {
    width: 330px;
    max-width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .banner-data {
    grid-template:
    "a a"
    "b c";
  }
  .banner-calculator-manage {
    padding: 30px;
  }
}

@media screen and (max-width: 768px) {
  .banner-img {
    width: 330px;
  }
  .links-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .about-grid {
    grid-template:
    "b"
    "a"
    "c";
  }
  .banner-calculator > div:first-child {
    flex-direction: column;
  }
}

@media screen and (max-width: 570px) {
  .banner-img {
   display: none;
  }
}

@media screen and (max-width: 420px) {
  .links-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .banner-calculator-manage {
    padding: 30px 20px;
  }
  .banner-calculator-manage > div {
    gap: 10px;
  }
}
</style>
