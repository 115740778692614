<template>
  <div class="order-create">
    <iframe
      :src="iframeSrc"
      frameborder="0"
      allow="clipboard-read; clipboard-write"
    />
  </div>
</template>

<script>
import {useRoute} from 'vue-router'
import {computed, getCurrentInstance} from 'vue'

export default {
  name: 'OrderCreate',
  setup() {
    const route = useRoute()
    const {companyData, iframeUrl} = getCurrentInstance().appContext.config.globalProperties

    const iframeSrc = computed(() => {
      const url = new URL(`${iframeUrl}/iframe/order/create/?color=%23${companyData.frameColor || companyData.color}&location_dispatch=${companyData.locationGuid}`)
      Object.entries(route.query || {}).forEach(([key, value]) => {
        url.searchParams.set(key, value.toString())
      })
      return url.href
    })

    return {
      iframeSrc
    }
  }
}
</script>

<style scoped>
iframe {
  height: calc(100vh - 92px);
  max-width: 100%;
  width: 100%;
}
@media screen and (max-width: 768px) {
  iframe {
    height: calc(100vh - 80px);
  }
}
</style>
