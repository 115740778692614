<template>
  <div class="banner-wrapper">
    <div class="banner">
      <div class="banner-header">
        <header-menu />
      </div>
      <div class="banner-body">
        <div>
          <span class="banner-body-title" v-html="companyData.bannerTitle" />
          <div class="banner-body-description" v-html="companyData.description" />
          <div v-if="companyData.documentId == 19" class="banner-body-data">
            <div>
              <img src="../assets/icons/balloon.svg">
              <div>Моментальное оповещение</div>
              <span>Информирование о статусе груза с момента приёма</span>
            </div>
            <div>
              <img src="../assets/icons/cards.svg">
              <div>Удобная оплата</div>
              <span>Оплачивайте перевозки онлайн или по QR-коду</span>
            </div>
            <div>
              <img src="../assets/icons/box.svg">
              <div>Простое получение</div>
              <span>Получайте грузы по коду из SMS</span>
            </div>
            <div>
              <img src="../assets/icons/point.svg">
              <div>Адресная доставка</div>
              <span>Доставка груза от двери до двери</span>
            </div>
          </div>
        </div>
        <t-calculator class="calculator" />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMenu from '@/components/HeaderMenu'
import TCalculator from '@/components/TCalculator'
import {getCurrentInstance} from 'vue'
export default {
  name: 'THeader',
  components: {TCalculator, HeaderMenu},
  props: {
    msg: String
  },
  setup() {
    const {companyData} = getCurrentInstance().appContext.config.globalProperties

    return {
      companyData,
    }
  }
}
</script>

<style scoped>
@import "../assets/css/banner.css";
.banner-wrapper {
  width: 100%;
  padding-top: 747px;
  position: relative;
  background-size: cover;
  background-image: url("../../public/local/banner.jpg");
  background-position: center;
}
.banner-wrapper .calculator {
  width: 420px;
  flex-shrink: 0;
}
.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: max(100%, 800px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  color: white;
}

.banner > div {
  width: 97%;
  max-width: 1240px;
}

@media screen and (max-width: 1240px) {
  .banner-body {
    flex-direction: column;
    justify-content: unset;
  }
  .banner-wrapper {
    padding-top: 840px;
  }
  .banner-wrapper .calculator {
    width: inherit;
  }
}

@media screen and (max-width: 768px) {
  .banner-wrapper {
    /*padding-top: 768px;*/
  }
  .calculator {
    display: none;
  }
  .banner-body {
    justify-content: center;
  }
  .banner-body > div:first-child {
    text-align: center;
  }
  .banner-body :deep(strong) {
    font-size: min(12vw, 47px);
  }
  .banner-body :deep(strong) br {
    display: none;
  }
  .banner-body-description {
    max-width: 536px;
  }
  .banner-body-data {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 480px) {
  .banner-wrapper {
    padding-top: 900px;
  }
  .banner-body > div:first-child {
    text-align: left;
  }
  .banner-wrapper {
    background-position: 50% 75%;
  }
  .banner-body > :deep(span) {
    font-size: min(12vw, 40px);
  }
}
</style>
