<template>
  <div class="ug-trans-home-page">
    <div class="banner-wrapper">
      <div class="banner">
        <header-menu style="position: relative" />
        <div class="banner-body">
          <div>
            <span class="banner-body-title pills">
              ЮГТранс ТЭК
            </span>
            <h2>БЫСТРО, КАЧЕСТВЕННО, НАДЁЖНО</h2>
            <div class="banner-body-description">
              Логистическая компания «ЮГТранс ТЭК» осуществляет полный комплекс услуг по перевозке сборных грузов.
              Использование передовых технологий в работе позволяет нам гарантировать максимальную сохранность ваших отправлений.
            </div>
          </div>
          <t-calculator class="calculator" style="" />
        </div>
        <div class="links-wrapper">
          <router-link v-for="{ title, route, icon } of links" :key="title" :to="route">
            <img :src="icon" />
            <span>{{ title }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="container-data">
        <div class="ugtrans-advantages">
          <h3 style="text-transform: unset">Наши <span class="color-primary">преимущества</span></h3>
          <div>
            <strong style="font-size: 20px">
              Мы придерживаемся высоких стандартов качества на всех этапах грузоперевозки,
              что позволяет гарантировать надёжную доставку грузов точно в срок
            </strong>
          </div>
          <img src="../assets/images/ugtrans_1.jpg" alt="" style="margin: 30px auto 0; max-width: min(100%, 740px)">
        </div>
        <div style="margin-top: auto" class="container-data-grid w-100">
          <div>
            <img src="../assets/icons/box_1.svg">
            <div>Простое получение</div>
            <span>Получайте грузы по коду из SMS</span>
          </div>
          <div>
            <img src="../assets/icons/map.svg">
            <div>Адресная доставка</div>
            <span>Доставка груза от двери до двери</span>
          </div>
          <div>
            <img src="../assets/icons/balloon.svg">
            <div>Моментальное оповещение</div>
            <span>Информирование о статусе груза с момента приёма</span>
          </div>
          <div>
            <img src="../assets/icons/card.svg">
            <div>Удобная оплата</div>
            <span>Оплачивайте перевозки онлайн или по QR-коду</span>
          </div>
        </div>
      </div>
    </div>
    <div class="ugtrans-div-wrapper">
      <div class="ugtrans-div">
        <div>
          <strong>5 000+</strong>
          <span>Грузов доставляем <br>каждый день</span>
        </div>
        <div>
          <strong>300+</strong>
          <span>Городов доставки</span>
        </div>
        <div>
          <strong>100 000+</strong>
          <span>Населенных пунктов</span>
        </div>
        <div>
          <strong>75+</strong>
          <span>Терминалов</span>
        </div>
      </div>
    </div>
    <div class="container" style="">
      <div class="container-data">
        <div class="w-100">
          <h3 style="text-transform: unset">
            Компания ЮГТранс ТЭК <br> Перевозка
            <span class="color-primary">сборных грузов</span>
          </h3>
          <span style="line-height: 24px">
          Мы предлагаем различные условия транспортировки грузов, подстраиваясь под индивидуальные запросы каждого клиента.
          Кроме того, наша компания предоставляет целый ряд сопутствующих услуг: упаковка грузов,<br> погрузо-разгрузочные работы,
          страхование и ответственное хранение отправлений.
        </span>
          <router-link :to="{ name: 'tariffs' }" class="t-button" style="margin-top: 30px">
            Расчёт тарифов
          </router-link>
        </div>
        <img src="../assets/images/ugtrans_2.jpg" class="w-100" style="max-width: 600px">
      </div>
    </div>
  </div>
</template>

<script>
import TCalculator from '@/components/TCalculator'
import {getCurrentInstance} from 'vue'
import HeaderMenu from '@/components/HeaderMenu'

export default {
  name: 'HomePage',
  components: {HeaderMenu, TCalculator},
  setup() {
    const {companyData} = getCurrentInstance().appContext.config.globalProperties

    const links = [
      {
        title: 'Рассчитать стоимость',
        shortTitle: 'Рассчитать стоимость',
        icon: require('../assets/icons/calc.svg'),
        route: { name: 'order-create' }
      },
      {
        title: 'Отследить груз',
        shortTitle: 'Отследить груз',
        icon: require('../assets/icons/search.svg'),
        route: { name: 'manage' }
      },
      {
        title: 'География грузоперевозок',
        shortTitle: 'География',
        icon: require('../assets/icons/geo.svg'),
        route: { name: 'geo' }
      },
      {
        title: 'Документы компании',
        shortTitle: 'Документы',
        icon: require('../assets/icons/docs.svg'),
        route: { name: 'documents' }
      },
      {
        title: 'Тарифы на услуги',
        shortTitle: 'Тарифы',
        icon: require('../assets/icons/coins.svg'),
        route: { name: 'tariffs' }
      },
      {
        title: 'Контакты',
        shortTitle: 'Контакты',
        icon: require('../assets/icons/phone.svg'),
        route: { name: 'contacts' }
      },
    ]

    return {
      companyData,
      links
    }
  }
}
</script>

<style scoped>
@import "../assets/css/banner.css";

.ug-trans-home-page {
  font-family: 'Roboto', sans-serif;
}

.ug-trans-home-page h2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.ug-trans-home-page :deep(.calculator) {
  width: 420px;
  flex-shrink: 0;
}
.ug-trans-home-page :deep(.calculator) h2 {
  font-size: 26px;
  line-height: 36px;
  text-transform: uppercase;
}
.ug-trans-home-page :deep(.menu-wrapper-header-title) {
  color: white;
}

.banner-wrapper {
  position: relative;
  background-image: url("../assets/images/ugtrans_banner.jpg");
  background-size: cover;
  margin-bottom: 200px;
}
.banner-wrapper * {
  z-index: 1;
}
.banner-wrapper .banner-body {
  color: white;
}
.banner-wrapper .pills {
  font-size: 26px;
  line-height: 1;
  color: white;
  background-color: var(--primary-color);
  display: inline-block;
  padding: 5px 15px 7px 10px;
  border-radius: 12px;
}

.banner {
  max-width: 1240px;
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
}

.container {
  max-width: 1240px;
  width: 97%;
  margin: 100px auto;
  justify-content: center;
}

.container .container-data-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.container .container-data-grid img {
  filter: var(--primary-filter);
  margin-bottom: 10px;
  width: 56px;
  height: 56px;
}

.container .container-data-grid > div > div {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 18px;
}

.container .container-data {
  margin: 100px 0;
  display: flex;
  gap: 60px;
  text-align: left;
}

.container .container-data img {
  object-fit: contain;
  /*max-width: min(520px, 100%);*/
}

.container .container-data h3 {
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 0;
}

.container .container-data strong {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}

.ugtrans-div-wrapper {
  width: 100%;
  max-width: 1920px;
  margin-top: -20px;
  background-image: url("../assets/images/ugtrans_div.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ugtrans-div {
  max-width: 1011px;
  color: white;
  display: grid;
  grid-template-columns: repeat(4, auto);
  row-gap: 40px;
  column-gap: 120px;
  align-items: start;
  margin-top: 45px;
  margin-bottom: 32px;
  font-size: 18px;
}
.ugtrans-div > div {
  display: flex;
  flex-direction: column;
}
.ugtrans-div > div > strong {
  font-size: 52px;
  /*font-weight: 800;*/
}

.ugtrans-advantages {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.links-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  margin-top: 50px;
  margin-bottom: -180px;
}
.links-wrapper > a {
  z-index: 200;
  padding: 44px 20px 25px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  color: var(--font-1);
  text-decoration: unset;
  border: 1px solid #D9DCDE;
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  transition: 200ms;
}
.links-wrapper > a img {
  filter: var(--primary-filter);
}

.links-wrapper > a:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}
.links-wrapper > a:hover img {
  filter: brightness(0) invert(1);
}

@media screen and (max-width: 1240px) {
  .links-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .container .container-data {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
  .ugtrans-div-wrapper {
    background-image: url("../assets/images/ugtrans_div_sm.jpg");
  }
  .ugtrans-div {
    grid-template-columns: repeat(2, auto);
  }
}

@media screen and (max-width: 900px) {
  .banner-body {
    flex-direction: column;
    margin-top: 100px;
  }
  .ug-trans-home-page :deep(.calculator) {
    width: 100%;
    width: -webkit-fill-available;
  }
}

@media screen and (max-width: 768px) {
  .banner-body {
    flex-direction: column;
    margin-top: 55px;
  }
  .links-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .ugtrans-div-wrapper {
    background-image: url("../assets/images/ugtrans_div_sm.jpg");
  }
  .ugtrans-div {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 420px) {
  .links-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .container .container-data-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
