<template>
  <footer>
    <div class="container">
      <div class="logo">
        <router-link to="/">
          <img src="../assets/images/diligans_footer.svg">
        </router-link>
        <span>Дилижанс <br>транспортная компания</span>
      </div>
      <div class="services">
        <div>
          Автомобильные грузовые перевозки и смежные транспортные услуги:
        </div>
        <div class="services-list">
          <div v-for="city of cityList" :key="city" style="display: flex; gap: 5px">
            <img src="../assets/icons/pin.svg" alt="" width="16">
            {{ city }}
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {computed, getCurrentInstance} from 'vue'
import {useRoute} from 'vue-router/dist/vue-router'

export default {
  name: 'DiligansFooter',
  setup() {
    const {companyData} = getCurrentInstance().appContext.config.globalProperties
    const route = useRoute()

    const isHome = computed(() => {
      return route.name === 'home'
    })

    const cityList = ['Москва', 'Екатеринбург', 'Пермь', 'Ханты-Мансийск', 'Нижневартовск', 'Санкт-Петербург', 'Тюмень', 'Челябинск', 'Сургут']

    return {
      companyData,
      cityList,
      isHome
    }
  }
}
</script>

<style scoped>
footer {
  background-color: #333333;
  padding: 30px 0 24px;
  text-align: left;
}
.container {
  width: 97%;
  max-width: 1240px;
  display: flex;
  gap: 32px;
  align-items: flex-start;
  justify-content: space-between;
  margin: auto;
  color: white;
  font-size: 18px;
}
img {
  /*margin: 30px 0 5px;*/
  /*filter: brightness(0) invert(1);*/
  /*margin-top: 10px;*/
}
.logo {
  display: flex;
  gap: 15px;
  font-size: 16px;
  color: var(--font-4);
  flex-grow: 1;
}
.services-list {
  margin-top: 18px;
  width: fit-content;
  font-size: 14px;
  color: var(--font-3);
  display: grid;
  column-gap: 32px;
  row-gap: 8px;
  grid-template-columns: repeat(5, auto);
}

@media screen and (max-width: 1240px) {
  .services-list {
    grid-template-columns: repeat(3, auto);
  }
}
@media screen and (max-width: 768px) {
  .services-list {
    grid-template-columns: repeat(2, auto);
  }
}
@media screen and (max-width: 600px) {
  .container {
    flex-direction: column;
  }
  .services {
    font-size: 16px;
  }
}
</style>
