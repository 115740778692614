<template>
  <div>
    <MainHeader v-if="!isHomePage" />
    <router-view />
    <diligans-footer v-if="+companyData.documentId === 18" />
    <MainFooter v-else />
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader'
import MainFooter from '@/components/MainFooter'
import {useRouter} from 'vue-router/dist/vue-router'
import {useRoute} from 'vue-router'
import {computed, getCurrentInstance, onBeforeMount, onBeforeUnmount, onMounted} from 'vue'
import DiligansFooter from '@/components/DiligansFooter'
export default {
  name: 'App',
  components: {DiligansFooter, MainFooter, MainHeader},

  setup() {
    const router = useRouter()
    const route = useRoute()
    const {companyData} = getCurrentInstance().appContext.config.globalProperties

    const isHomePage = computed(() => {
      return route.name === 'home'
    })

    function messageCB(message) {
      if (message.data.type === 'order-manage') {
        router.push({ name: 'order-edit', params: { id: message.data.other.id }, query: { phoneNumber: message.data.other.phone } })
      } else if (message.data.type === 'order-create') {
        router.push({ name: 'order-create', query: message.data.other?.query })
      }
    }

    onMounted(() => {
      window.addEventListener('message', messageCB)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('message', messageCB)
    })

    onBeforeMount(() => {
      let filter = ''
      switch (+companyData.documentId) {
        case 18:
          filter = 'brightness(0) saturate(100%) invert(84%) sepia(12%) saturate(7124%) hue-rotate(324deg) brightness(98%) contrast(106%)';
          break;
        case 19:
          filter = 'brightness(0) saturate(100%) invert(91%) sepia(44%) saturate(728%) hue-rotate(333deg) brightness(99%) contrast(98%)';
          break;
        case 20:
          filter = 'brightness(0) saturate(100%) invert(66%) sepia(45%) saturate(6598%) hue-rotate(166deg) brightness(98%) contrast(98%)';
          break;
        case 124:
          filter = 'brightness(0) saturate(100%) invert(35%) sepia(88%) saturate(2188%) hue-rotate(329deg) brightness(106%) contrast(101%)';
          break;
      }
      document.documentElement.style.setProperty('--primary-color', `#${companyData.color}`);
      document.documentElement.style.setProperty('--primary-filter', filter);
    })

    return {
      isHomePage,
      companyData
    }
  }
}
</script>

<style>
</style>
