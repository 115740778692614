import { createApp } from 'vue'
import App from './App.vue'
import Homepage from '@/pages/HomePage'
import TransportHomePage from '@/pages/TransportHomePage'
import DiligansHomePage from '@/pages/DiligansHomePage'
import UgTransHomePage from '@/pages/UgTransHomePage'
import SeverTransHomePage from '@/pages/SeverTransHomePage'
import OrderManage from '@/pages/OrderManage'
import OrderCreate from '@/pages/OrderCreate'
import OrderEdit from '@/pages/OrderEdit'
import GeoPage from '@/pages/GeoPage'
import TariffsPage from '@/pages/TariffsPage'
import DocumentPage from '@/pages/DocumentPage'
import ContactPage from '@/pages/ContactPage'
import NotFound from '@/pages/NotFound'
import { createRouter, createWebHistory } from 'vue-router'
import '@/assets/css/main.css'

const companyData = require('../public/local/company.json')

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
  {
    path: "/",
    name: "home",
    component: Homepage,
  },
  {
    path: "/manage",
    name: "manage",
    component: OrderManage,
  },
  {
    path: "/order",
    name: "order-create",
    component: OrderCreate,
  },
  {
    path: "/order/:id",
    name: "order-edit",
    component: OrderEdit,
  },
  {
    path: "/geo",
    name: "geo",
    component: GeoPage,
  },
  {
    path: "/tariffs",
    name: "tariffs",
    component: TariffsPage,
  },
  {
    path: "/documents",
    name: "documents",
    component: DocumentPage,
  },
  {
    path: "/contacts",
    name: "contacts",
    component: ContactPage,
  },
  // will match everything and put it under `$route.params.pathMatch`
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
];

if (+companyData.documentId === 124) {
  companyData.color = 'FF455D'
  companyData.headerTitle = 'Транспортные технологии'
  routes[0].component = TransportHomePage
} else if (+companyData.documentId === 18) {
  companyData.color = 'FF9E45'
  companyData.headerTitle = 'Дилижанс транспортная компания'
  routes[0].component = DiligansHomePage
} else if (+companyData.documentId === 19) {
  companyData.color = 'FAE05A'
  companyData.frameColor = '4A4949'
  companyData.headerTitle = 'Транспортная компания Севертранс'
  routes[0].component = SeverTransHomePage
} else if (+companyData.documentId === 20) {
  companyData.color = '03A9F4'
  companyData.headerTitle = 'Транспортная компания ЮГТранс ТЭК'
  routes[0].component = UgTransHomePage
  companyData.address = ''
  companyData.locationGuid = 'ff6a6db8-0125-11e5-80c7-00155d903d03'
}

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
    faMapLocationDot,
    faLocationDot,
    faList,
    faFileLines,
    faFilePdf,
    faCalendarDays,
    faMagnifyingGlass,
    faMoneyCheck,
    faFile,
    faPhone,
    faTableList,
} from '@fortawesome/free-solid-svg-icons'
import {
    faTelegramPlane,
} from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(
    faMapLocationDot, faLocationDot, faTelegramPlane,
    faList, faFileLines, faFilePdf, faCalendarDays,
    faMagnifyingGlass, faMoneyCheck, faFile, faPhone, faTableList
)

const app = createApp(App)

app.config.globalProperties.companyData = companyData
app.config.globalProperties.iframeUrl = process.env.VUE_APP_HOST

app.use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
