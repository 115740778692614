<template>
  <div class="home-page">
    <THeader/>
    <div class="container">
      <t-calculator v-if="showCalculator" style="z-index: 1000;" />
      <div v-if="companyData.documentId == 19" class="container-data">
        <div>
          <h3>Севертранс</h3>
          <strong>Предоставляем широкий выбор логистических услуг для бизнеса в различных отраслях экономики</strong>
          <p>
            Наша компания является ведущим перевозчиком сборных грузов на территории Северо-Западного федерального округа.
            Мы предоставляем целый ряд сопутствующих услуг для ваших отправлений – от надёжной упаковки до страхования грузов.
          </p>
          <div class="container-data-warning">Миссия «Севертранс» — стать надёжными партнёрами для каждого клиента</div>
          <p>
            Компания уже более 9 лет работает в области транспортной логистики.
            За это время нам удалось создать широкую партнёрскую сеть в сфере грузоперевозок, что позволило расширить возможности для бизнеса наших клиентов.
          </p>
        </div>
        <img src="../assets/severtrans.jpg" alt="">
      </div>
      <div class="links-wrapper">
        <router-link v-for="{ title, route, icon } of links" :key="title" :to="route">
          <img :src="icon" />
          <span>{{ title }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import THeader from '@/components/THeader'
import TCalculator from '@/components/TCalculator'
import { useWindowSize } from '@vueuse/core'
import {computed, getCurrentInstance} from 'vue'

export default {
  name: 'HomePage',
  components: {TCalculator, THeader},
  setup() {
    const { width } = useWindowSize()
    const {companyData} = getCurrentInstance().appContext.config.globalProperties

    const links = [
      {
        title: 'Рассчитать стоимость',
        icon: require('../assets/icons/calc.svg'),
        route: { name: 'order-create' }
      },
      {
        title: 'Отследить груз',
        icon: require('../assets/icons/search.svg'),
        route: { name: 'manage' }
      },
      {
        title: 'География грузоперевозок',
        icon: require('../assets/icons/geo.svg'),
        route: { name: 'geo' }
      },
      {
        title: 'Документы компании',
        icon: require('../assets/icons/docs.svg'),
        route: { name: 'documents' }
      },
      {
        title: 'Тарифы на услуги',
        icon: require('../assets/icons/coins.svg'),
        route: { name: 'tariffs' }
      },
      {
        title: 'Контакты',
        icon: require('../assets/icons/phone.svg'),
        route: { name: 'contacts' }
      },
    ]

    const showCalculator = computed(() => {
      return width.value <= 768
    })

    return {
      companyData,
      showCalculator,
      links
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 97%;
  max-width: 1240px;
  margin: -40px auto 40px;
  align-items: center;
  justify-content: center;
}

.container .container-data {
  margin: 100px 0;
  display: flex;
  gap: 60px;
  text-align: left;
}

.container .container-data img {
  object-fit: contain;
  /*max-width: min(520px, 100%);*/
}

.container .container-data h3 {
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 0;
}

.container .container-data strong {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}

.links-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}
.links-wrapper > a {
  z-index: 200;
  padding: 44px 20px 25px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  color: var(--font-1);
  text-decoration: unset;
  border: 1px solid #D9DCDE;
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  transition: 200ms;
}
.links-wrapper > a img {
  filter: var(--primary-filter);
}

.links-wrapper > a:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}
.links-wrapper > a:hover img {
  filter: brightness(0) invert(1);
}

@media screen and (max-width: 1240px) {
  .links-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .container .container-data {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
}

@media screen and (max-width: 768px) {
  .links-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 420px) {
  .links-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
