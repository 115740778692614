<template>
  <div class="menu-wrapper" :class="`header-menu-${companyData.documentId}`">
    <router-link to="/">
      <img :src="logo">
      <span v-if="isLeftText" class="menu-wrapper-header-title">{{ companyData.headerTitle }}</span>
    </router-link>
    <span v-if="!isLeftText" class="menu-wrapper-header-title">{{ companyData.headerTitle }}</span>
    <router-link :to="{ name: 'contacts' }" class="become-client">
      Стать клиентом
    </router-link>
  </div>
</template>

<script>
import {computed, getCurrentInstance, ref} from 'vue'

export default {
  name: 'HeaderMenu',
  setup() {
    const {companyData} = getCurrentInstance().appContext.config.globalProperties
    const open = ref(false)

    const isLeftText = computed(() => {
      return +companyData.documentId === 124 || +companyData.documentId === 18
    })

    const imgOptions = computed(() => {
      if (+companyData.documentId === 20) {
        return {height: 45}
      }
      return {}
    })

    const logo = computed(() => {
      switch (+companyData.documentId) {
        case 18:
          return require('../assets/images/diligans_logo.svg')
        case 19:
          return require('../assets/images/severtrans_logo.svg')
        case 20:
          return require('../assets/images/ugtrans_logo.svg')
        case 124:
          return require('../assets/images/transport_technology_logo.svg')

      }
      return ''//require('../assets/images/ugtrans_logo.svg')
    })

    return {
      open,
      companyData,
      isLeftText,
      imgOptions,
      logo
    }
  }
}
</script>

<style scoped>
.header-menu-18 .menu-wrapper-header-title {
  color: var(--font-2);
}

.header-menu-19 .become-client {
  color: var(--font-1);
}

.header-menu-20,.header-menu-19 .menu-wrapper-header-title {
  color: white;
}

.menu-wrapper {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
}
.menu-wrapper-header-title {
  font-size: 18px;
}

.menu-wrapper a {
  display: flex;
  align-items: center;
  gap: 15px;
  color: white;
  text-decoration: unset;
  font-size: 18px;
  text-align: left;
}

.become-client {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 48px;
  border-radius: 4px;
  background-color: var(--primary-color);
  transition: background-color 0.15s ease;
}

.become-client:hover {
  background-color: color-mix(in srgb, var(--primary-color), black 10%);
}

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 768px) {
  .become-client {
    display: none !important;
  }
}
@media screen and (max-width: 480px) {
  .menu-wrapper a {
    flex-wrap: wrap;
  }
  .menu-wrapper {
    flex-wrap: wrap;
  }
  .menu-wrapper-header-title {
    display: none;
  }
}
</style>
