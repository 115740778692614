<template>
  <footer>
    <div class="container">

      <router-link v-if="hasIcon" to="/">
        <img src="local/logo.svg">
      </router-link>
      <span v-else v-html="title" />
      <div v-if="+companyData.documentId === 124" class="address">{{ companyData.headerTitle }}</div>
      <div v-else class="address">{{ companyData.address }}</div>
    </div>
  </footer>
</template>

<script>
import {computed, getCurrentInstance} from 'vue'
import {useRoute} from 'vue-router/dist/vue-router'

export default {
  name: 'MainFooter',
  setup() {
    const {companyData} = getCurrentInstance().appContext.config.globalProperties
    const route = useRoute()

    const isHome = computed(() => {
      return route.name === 'home'
    })

    const hasIcon = +companyData.documentId === 20 || +companyData.documentId === 124

    const title = +companyData.documentId === 19
      ? '<div style="text-transform: uppercase">Севертранс</div> грузоперевозки по Северо-Западу'
      : companyData.title

    return {
      companyData,
      isHome,
      hasIcon,
      title
    }
  }
}
</script>

<style scoped>
footer {
  background-color: var(--font-1);
  padding: 30px 0 24px;
}
.container {
  width: 97%;
  max-width: 1240px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  color: white;
  font-size: 18px;
}
img {
  /*margin: 30px 0 5px;*/
  filter: brightness(0) invert(1);
  margin-top: 10px;
}
.address {
  color: var(--font-4);
  font-size: 14px;
  line-height: 20px;
  max-width: 240px;
}
</style>
