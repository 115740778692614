<template>
  <div class="geo-page">
    <iframe
      :src="iframeSrc"
      frameborder="0"
    />
  </div>
</template>

<script>
import {useRoute} from 'vue-router/dist/vue-router'
import {computed, getCurrentInstance} from 'vue'

export default {
  name: 'OrderManage',
  setup() {
    const route = useRoute()
    const {companyData, iframeUrl} = getCurrentInstance().appContext.config.globalProperties

    const iframeSrc = computed(() => {
      const url = new URL(`${iframeUrl}/iframe/geo/?color=%23${companyData.frameColor || companyData.color}&location=${companyData.locationGuid}`)
      Object.entries(route.query || {}).forEach(([key, value]) => {
        url.searchParams.set(key, value.toString())
      })
      return url.href
    })

    return {
      iframeSrc
    }
  }
}
</script>

<style scoped>
.geo-page {
  background-color: #f9f9f9;
}
iframe {
  height: calc(100vh - 92px);
  max-width: 100%;
  width: 100%;
}
@media screen and (max-width: 768px) {

}
</style>
