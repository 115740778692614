<template>
  <div class="transport-home-page">
    <div class="banner-wrapper">
      <div class="banner">
        <header-menu style="position: relative" />
        <div class="banner-data">
          <div>
            <div class="banner-data-label">
              точно, качественно, <br>надежно
            </div>
            <div class="transport-description">
              Предоставляем логистические услуги по всей территории России.
              Имеем большой опыт в сфере грузоперевозок, что позволяет обеспечить надежную и качественную доставку отправлений точно в срок.
            </div>
            <div class="banner-data-list">
              <div>
                <img src="../assets/icons/geo.svg">
                <span>Широкая география <br>грузоперевозок</span>
              </div>
              <div>
                <img src="../assets/icons/car.svg">
                <span>Быстрая доставка <br>от 2 дней</span>
              </div>
              <div>
                <img src="../assets/icons/terminal.svg">
                <span>Забор груза со склада <br>отправителя</span>
              </div>
            </div>
          </div>
          <t-calculator style="z-index: 1000; border-radius: unset; flex-grow: 1" />
        </div>
        <div class="links-wrapper">
          <router-link v-for="{ shortTitle, route } of links" :key="shortTitle" :to="route">
            <span>{{ shortTitle }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="transportation">
        <div class="container-warning" style="margin-bottom: 50px">
          <h5>Автомобильные грузоперевозки по России</h5>
          <p>
            Мы являемся ведущей логистической компанией, осуществляющей полный комплекс услуг по перевозке сборных грузов на территории России.
            Наша миссия – объединить многолетний опыт и современные технологии, чтобы обеспечить быструю доставку и максимальную сохранность ваших отправлений.
          </p>
        </div>
        <img src="../assets/images/transport_technology_1.jpg" alt="">
      </div>
      <div>
        <div style="font-size: 22px; font-weight: 600; text-align: left">
          Нас выбирают, <br>
          <span class="color-primary">потому что доверяют</span>
        </div>
        <div class="why-us-grid">
          <div>
            <strong>5 000+</strong>
            <span>Грузов доставляем <br>каждый день</span>
          </div>
          <div>
            <strong>300+</strong>
            <span>Городов доставки</span>
          </div>
          <div>
            <strong>100 000+</strong>
            <span>Населенных пунктов</span>
          </div>
          <div>
            <strong>75+</strong>
            <span>Терминалов</span>
          </div>
        </div>
      </div>
    </div>
    <div class="about-wrapper">
      <div class="about">
        <div class="about-text">
          <p>Доверие наших клиентов основано на строгом контроле качества во всех аспектах работы</p>
          <p style="font-size: 16px; line-height: 24px; font-weight: 400; color: var(--font-3)">
            Компания «Транспортные технологии» располагает широкой партнерской сетью по всей России.
            Кроме транспортировки отправлений, мы предлагаем широкий выбор сопутствующих услуг по упаковке, хранению и страхованию грузов.
          </p>
          <p class="color-primary" style="margin-top: 40px">
            Отправим груз в любую точку России!
          </p>
          <div style="width: 100px; height: 2px; background-color: var(--primary-color)" />
        </div>
        <img src="../assets/images/transport_technology_2.jpg" />
      </div>
    </div>
    <div class="container">
      <div class="individual">
        <div>
          <strong>Индивидуальный подход</strong>
          <span>Мы рады предложить комфортные условия индивидуального обслуживания, соответствующие запросам каждого клиента</span>
        </div>
        <router-link :to="{ name: 'contacts' }" class="t-button">
          Стать клиентом
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import TCalculator from '@/components/TCalculator'
import {getCurrentInstance} from 'vue'
import HeaderMenu from '@/components/HeaderMenu'

export default {
  name: 'HomePage',
  components: {HeaderMenu, TCalculator},
  setup() {
    const {companyData} = getCurrentInstance().appContext.config.globalProperties

    const links = [
      {
        title: 'Рассчитать стоимость',
        shortTitle: 'Рассчитать стоимость',
        icon: require('../assets/icons/calc.svg'),
        route: { name: 'order-create' }
      },
      {
        title: 'Отследить груз',
        shortTitle: 'Отследить груз',
        icon: require('../assets/icons/search.svg'),
        route: { name: 'manage' }
      },
      {
        title: 'География грузоперевозок',
        shortTitle: 'География',
        icon: require('../assets/icons/geo.svg'),
        route: { name: 'geo' }
      },
      {
        title: 'Документы компании',
        shortTitle: 'Документы',
        icon: require('../assets/icons/docs.svg'),
        route: { name: 'documents' }
      },
      {
        title: 'Тарифы на услуги',
        shortTitle: 'Тарифы',
        icon: require('../assets/icons/coins.svg'),
        route: { name: 'tariffs' }
      },
      {
        title: 'Контакты',
        shortTitle: 'Контакты',
        icon: require('../assets/icons/phone.svg'),
        route: { name: 'contacts' }
      },
    ]

    return {
      companyData,
      links
    }
  }
}
</script>

<style scoped>
.transport-home-page :deep(.calculator) h2 {
  font-size: 26px;
  line-height: 36px;
  text-transform: uppercase;
}
.transport-description {
  max-width: 650px;
  line-height: 24px;
  text-align: left
}

.banner-wrapper {
  position: relative;
  background-image: url("../assets/images/transport_technology.jpg");
  background-size: cover;
  /*display: flex;*/
  /*justify-content: center;*/
}
.banner-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.94;
  background-color: #0C1323;
}
.banner-wrapper * {
  z-index: 1;
}

.banner {
  max-width: 1240px;
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
}
.banner-data {
  position: relative;
  border: 1px solid white;
  display: flex;
  justify-content: space-between;
  color: white;
  padding-left: 50px;
  column-gap: 50px;
}
.banner-data > div:first-child {
  display: flex;
  flex-direction: column;
}

.banner-data-label {
  display: inline-block;
  margin: 80px 0 40px -190px;
  background-color: var(--primary-color);
  padding: 33px 68px 33px 190px;
  font-size: min(11vw, 42px);
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
}
.banner-data-list {
  display: flex;
  justify-content: space-between;
  max-width: 570px;
  margin-top: 50px;
  margin-bottom: 60px;
}
.banner-data-list > div {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  color: var(--font-4);
  text-align: center;
  font-size: 14px;
}
.banner-data-list img {
  filter: var(--primary-filter);
  width: 32px;
  height: 32px;
}

.transportation {
  display: flex;
  column-gap: 50px;
  align-items: flex-start;
}
.transportation img {
  max-width: 100%;
}

.about-wrapper {
  background: linear-gradient(288deg, rgba(34, 72, 134, 0.36) 0%, rgba(0, 0, 0, 0.00) 30%), #0C1323;
  color: white;
}
.about {
  max-width: 1240px;
  width: calc(100% - 50px);
  margin: 0 auto;
  display: flex;
  text-align: left;
  font-size: 22px;
  font-weight: 600;
}

.about img {
  object-fit: contain;
}

.about-text {
  max-width: 500px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.individual {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #0C1323;
  padding: 40px 50px;
}
.individual > div {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 20px;
  max-width: 665px;
}
.individual strong {
  font-size: 22px;
}

.container {
  max-width: 1240px;
  /*display: flex;*/
  /*flex-direction: column;*/
  width: 97%;
  margin: 100px auto;
  /*align-items: center;*/
  justify-content: center;
}
.container-warning {
  padding-left: 48px;
  border-left: 2px solid var(--primary-color);
  text-align: left;
}
.container-warning h5 {
  font-size: 28px;
  font-weight: 600;
  margin-top: unset;
  margin-bottom: 20px;
}

.links-wrapper {
  width: 100%;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-top: 40px;
}
.links-wrapper > a {
  z-index: 200;
  padding: 12px 32px;
  background-color: var(--font-1);
  align-items: center;
  color:white;
  text-decoration: unset;
  border-radius: 64px;
  box-shadow: var(--box-shadow);
  transition: 200ms;
}

.links-wrapper > a:hover {
  background-color: var(--primary-color);
}

.why-us-grid {
  display: flex;
  flex-wrap: wrap;
  column-gap: 80px;
  row-gap: 30px;
  margin-top: 30px;
}
.why-us-grid > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.why-us-grid > div > strong {
  font-size: 36px;
  color: var(--primary-color);
  font-weight: 400;
}
.why-us-grid > div > span {
  font-size: 14px;
  color: var(--font-3);
}

@media screen and (max-width: 1240px) {
  .links-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .banner-data {
    flex-direction: column;
    padding-left: unset;
  }
  .banner-data-label {
    width: fit-content;
    display: block;
    padding-left: 240px;
  }
  .banner-data-list {
    padding-left: 50px;
  }
  .transport-description {
    padding-left: 50px;
    padding-right: 20px;
    max-width: unset;
  }
}

@media screen and (max-width: 1000px) {
  .about img {
    display: none
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 50px;
  }
  .links-wrapper {
    width: calc(100% - 50px);
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  .links-wrapper > a {
    flex-grow: 1;
  }
  .banner {
    width: unset;
  }
  .banner :deep(.menu-wrapper) {
    padding-left: 16px;
    width: unset;
  }
  .banner-data :deep(.calculator) {
    max-width: 100%;
    box-sizing: border-box;
  }
  .banner-data > div:first-child {
    padding-left: 20px;
  }
  .banner-data-list {
    flex-direction: column;
    gap: 40px;
  }
  .transportation {
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }
  .why-us-grid {
    flex-direction: column;
  }

  .banner-data-label {
    padding-left: 20px;
    padding-right: 0;
    margin-left: -20px;
    margin-right: 0;
    width: 100%;
  }
  .banner-data-list {
    padding-left: 20px;
  }
  .transport-description {
    padding-left: 20px;
    padding-right: 20px;
    max-width: unset;
  }
}

@media screen and (max-width: 420px) {
  .about-text {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .individual {
    padding: 40px 20px;
  }
}
</style>
