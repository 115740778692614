<template>
  <div class="tariffs-page">
    <iframe
      :src="iframeSrc"
      frameborder="0"
    />
  </div>
</template>

<script>
import {useRoute} from 'vue-router/dist/vue-router'
import {computed, getCurrentInstance} from 'vue'

export default {
  name: 'OrderManage',
  setup() {
    const route = useRoute()
    const {companyData, iframeUrl} = getCurrentInstance().appContext.config.globalProperties

    const iframeSrc = computed(() => {
      const url = new URL(`${iframeUrl}/iframe/tariffs`)
      url.searchParams.append('color', `#${companyData.frameColor || companyData.color}`)
      url.searchParams.append('location', companyData.locationGuid)
      if (companyData.orgId) {
        url.searchParams.append('orgId', companyData.orgId)
      }
      Object.entries(route.query || {}).forEach(([key, value]) => {
        url.searchParams.set(key, value.toString())
      })
      return url.href
    })

    return {
      iframeSrc
    }
  }
}
</script>

<style scoped>
.tariffs-page {
  background-color: #f9f9f9;
}
iframe {
  height: max(calc(100vh - 217px), 500px);
  max-width: 100%;
  width: 100%;
}
@media screen and (max-width: 768px) {

}
</style>
