<template>
  <div class="manage-page">
    <iframe
      :src="iframeSrc"
      frameborder="0"
      allow="clipboard-read; clipboard-write"
    />
  </div>
</template>

<script>
import {useRoute} from 'vue-router/dist/vue-router'
import {computed, getCurrentInstance} from 'vue'

export default {
  name: 'OrderManage',
  setup() {
    const route = useRoute()
    const {companyData, iframeUrl} = getCurrentInstance().appContext.config.globalProperties

    const iframeSrc = computed(() => {
      const url = new URL(`${iframeUrl}/iframe/manage`)
      url.searchParams.append('color', `#${companyData.frameColor || companyData.color}`)
      Object.entries(route.query || {}).forEach(([key, value]) => {
        url.searchParams.set(key, value.toString())
      })
      return url.href
    })

    return {
      iframeSrc
    }
  }
}
</script>

<style scoped>
.manage-page {
  padding-top: 50px;
  background-color: #f9f9f9;
}
iframe {
  height: max(calc(100vh - 267px), 500px);
  max-width: 100%;
  width: 100%;
}
@media screen and (max-width: 768px) {

}
</style>
