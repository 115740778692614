<template>
  <div class="calculator">
    <h2>Рассчитать стоимость</h2>
    <iframe
      :src="iframeSrc"
      frameborder="0"
    />
  </div>
</template>

<script>
import {computed, getCurrentInstance} from 'vue'

export default {
  name: 'TCalculator',

  setup() {
    const {companyData, iframeUrl} = getCurrentInstance().appContext.config.globalProperties

    const iframeSrc = computed(() => {
      const url = new URL(`${iframeUrl}/iframe/calculator`)
      url.searchParams.append('color', `#${companyData.color}`)
      if (+companyData.documentId === 19) {
        url.searchParams.append('text_color', `black`)
      }
      url.searchParams.append('dispatch_point', `${companyData.locationGuid}`)
      return url.href
    })

    return {
      iframeSrc
    }
  }
}
</script>

<style scoped>
.calculator {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: var(--font-1);
  padding: 10px;
  min-width: 300px;
  border-radius: 12px;
  border: 1px solid var(--line);
  box-shadow: var(--box-shadow);
  container-type: inline-size;
}
.calculator iframe {
  height: 270px;
  flex-grow: 1;
  width: 100%;
  margin: 0;
  line-height: 1;
  border: none;
}
.calculator h2 {
  margin-bottom: 0;
  padding: 0 10px;
}

@container (max-width: 736px) {
  .calculator iframe {
    height: 455px;
  }
}

@container (max-width: 360px) {
  .calculator iframe {
    height: 482px;
  }
}

@container (max-width: 340px) {
  .calculator iframe {
    height: 596px;
  }
}

@container (max-width: 306px) {
  .calculator iframe {
    height: 622px;
  }
}

@media screen and (max-width: 368px) {
  .calculator {
    min-width: 100%;
    padding: 0;
  }
  .calculator iframe {
    min-height: 650px;
  }
}
</style>
