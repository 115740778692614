<template>
  <header :class="`header-${companyData.documentId}`">
    <div class="content">
      <header-menu/>
    </div>
  </header>
</template>

<script>
import HeaderMenu from '@/components/HeaderMenu'
import {getCurrentInstance} from 'vue'

export default {
  name: 'MainHeader',
  components: {HeaderMenu},
  setup() {
    const {companyData} = getCurrentInstance().appContext.config.globalProperties


    return {
      companyData
    }
  }
}
</script>

<style scoped>
header {
  background-color: var(--font-1);
}
.header-18 {
  background-color: #F8F8F8;
}

.content {
  display: flex;
  margin: auto;
  width: 97%;
  max-width: 1240px;
}
</style>
