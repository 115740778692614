<template>
  <div class="not-found-page">

  </div>
</template>

<script>

export default {
  name: 'NotFound',
}
</script>

<style scoped>
.not-found-page {
  height: calc(100vh - 143px);
  background: url("../assets/404.png") center no-repeat;
  background-color: black;
}
@media screen and (max-width: 768px) {
  .not-found-page {
    background-size: contain;
  }
}
</style>
