<template>
  <div class="sever-trans-home-page">
    <div class="banner-wrapper">
      <div class="banner">
        <header-menu style="position: relative" />
        <div class="banner-body">
          <div>
            <div class="banner-body-title">
              <strong>СЕВЕРТРАНС</strong>
            </div>
            <div class="banner-body-description">
              <p>Доставка сборных грузов</p>
              <strong>по северо-западу</strong>
            </div>
            <div class="banner-body-data">
              <div>
                <img src="../assets/icons/balloon.svg">
                <div>Моментальное оповещение</div>
                <span>Информирование о статусе груза с момента приёма</span>
              </div>
              <div>
                <img src="../assets/icons/cards.svg">
                <div>Удобная оплата</div>
                <span>Оплачивайте перевозки онлайн или по QR-коду</span>
              </div>
              <div>
                <img src="../assets/icons/box.svg">
                <div>Простое получение</div>
                <span>Получайте грузы по коду из SMS</span>
              </div>
              <div>
                <img src="../assets/icons/point.svg">
                <div>Адресная доставка</div>
                <span>Доставка груза от двери до двери</span>
              </div>
            </div>
          </div>
          <t-calculator class="calculator" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="container-data">
        <div>
          <h3>Севертранс</h3>
          <strong>Предоставляем широкий выбор логистических услуг для бизнеса в различных отраслях экономики</strong>
          <p>
            Наша компания является ведущим перевозчиком сборных грузов на территории Северо-Западного федерального округа.
            Мы предоставляем целый ряд сопутствующих услуг для ваших отправлений – от надёжной упаковки до страхования грузов.
          </p>
          <div class="container-data-warning">Миссия «Севертранс» — стать надёжными партнёрами для каждого клиента</div>
          <p>
            Компания уже более 9 лет работает в области транспортной логистики.
            За это время нам удалось создать широкую партнёрскую сеть в сфере грузоперевозок, что позволило расширить возможности для бизнеса наших клиентов.
          </p>
        </div>
        <img src="../assets/severtrans.jpg" alt="" style="max-width: 100%">
      </div>
      <div class="links-wrapper">
        <router-link v-for="{ title, route, icon } of links" :key="title" :to="route">
          <img :src="icon" />
          <span>{{ title }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import TCalculator from '@/components/TCalculator'
import HeaderMenu from '@/components/HeaderMenu'

export default {
  name: 'HomePage',
  components: {HeaderMenu, TCalculator},
  setup() {
    const links = [
      {
        title: 'Рассчитать стоимость',
        shortTitle: 'Рассчитать стоимость',
        icon: require('../assets/icons/calc.svg'),
        route: { name: 'order-create' }
      },
      {
        title: 'Отследить груз',
        shortTitle: 'Отследить груз',
        icon: require('../assets/icons/search.svg'),
        route: { name: 'manage' }
      },
      {
        title: 'География грузоперевозок',
        shortTitle: 'География',
        icon: require('../assets/icons/geo.svg'),
        route: { name: 'geo' }
      },
      {
        title: 'Документы компании',
        shortTitle: 'Документы',
        icon: require('../assets/icons/docs.svg'),
        route: { name: 'documents' }
      },
      {
        title: 'Тарифы на услуги',
        shortTitle: 'Тарифы',
        icon: require('../assets/icons/coins.svg'),
        route: { name: 'tariffs' }
      },
      {
        title: 'Контакты',
        shortTitle: 'Контакты',
        icon: require('../assets/icons/phone.svg'),
        route: { name: 'contacts' }
      },
    ]

    return {
      links
    }
  }
}
</script>

<style scoped>
@import "../assets/css/banner.css";

.sever-trans-home-page {
  font-family: 'Roboto', sans-serif;
}
.sever-trans-home-page :deep(.calculator) {
  width: 420px;
  flex-shrink: 0;
}
.sever-trans-home-page :deep(.calculator) h2 {
  font-size: 26px;
  line-height: 36px;
  text-transform: uppercase;
}
.sever-trans-home-page :deep(.menu-wrapper-header-title) {
  color: white;
}

.sever-trans-home-page .banner-body-title :deep(strong) {
  font-size: min(14vw, 72px);
  line-height: min(15vw, 98px);
  padding-bottom: 0;
  font-family: 'Open Sans', sans-serif;
}

.banner-wrapper {
  position: relative;
  background-image: url("../assets/images/severtrans_banner.jpg");
  background-size: cover;
  background-position: center;
}
.banner-wrapper * {
  z-index: 1;
}
.banner-wrapper .banner-body {
  color: white;
  margin-bottom: unset;
}

.banner {
  max-width: 1240px;
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
}

.container {
  max-width: 1240px;
  width: 97%;
  margin: 100px auto;
  justify-content: center;
}

.container .container-data {
  margin: 100px 0;
  display: flex;
  gap: 60px;
  text-align: left;
}

.container .container-data img {
  object-fit: contain;
  /*max-width: min(520px, 100%);*/
}

.container .container-data h3 {
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 0;
  font-family: 'Open Sans', sans-serif;
}

.container .container-data strong {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}

.links-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}
.links-wrapper > a {
  z-index: 200;
  padding: 44px 20px 25px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  color: var(--font-1);
  text-decoration: unset;
  border: 1px solid #D9DCDE;
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  transition: 200ms;
}
.links-wrapper > a img {
  /*filter: var(--primary-filter);*/
}

.links-wrapper > a:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.links-wrapper > a:hover img {
  filter: brightness(0);
}

@media screen and (max-width: 1240px) {
  .links-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .container .container-data {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
}

@media screen and (max-width: 1000px) {
  .banner-body {
    flex-direction: column;
    margin-top: 100px;
  }
  .sever-trans-home-page :deep(.calculator) {
    width: 100%;
    width: -webkit-fill-available;
  }
}

@media screen and (max-width: 768px) {
  .banner-body {
    flex-direction: column;
    margin-top: 40px;
  }
  .links-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 420px) {
  .links-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .banner {
    padding-bottom: unset;
    margin-bottom: 600px;
  }
  .sever-trans-home-page :deep(.calculator) {
    margin-bottom: -500px;
  }
}
</style>
